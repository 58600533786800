@import "fonts";

// Fonts
$font-family: "normal";
$font-family-bold: "bold";
$font-family-extraBold: "extraBold";
$font-family-black: "black";
$font-family-medium: "normal";
$font-family-regular: "normal";
$font-family-black: "bold";
$font-family-light: "light";
$font-family-thin: "light";
$font-family-header: "normal";
$font-family-placeholder:"light";

// Font-size
$font-size-xl: 40px;
// 38px h1 ?
$font-size-lg: 20px;
$font-size-md: 16px;
$font-size: 14px;
$font-size-sm: 12px;
$font-size-xs: 11px;
$font-size-xxs: 10px;

// Colors
$txt-color: #000000;
$nav-color: #ffffff;

$overlay: rgba(0, 0, 0, 0.5);
$content-bg: rgba(255, 255, 255, 0.15);
$box-bg: rgba(255, 255, 255, 0.25);

$modalWhiteOverlay: rgba(0, 0, 0, 0.5);
$modalShadowColor: rgba(0, 0, 0, 0.18);

// Layout
/*
Typical resolutions for some popular screen sizes:
19-inch screen (standard ratio): 1280 x 1024 pixels.
20-inch screen (standard ratio): 1600 x 1200 pixels.
22-inch screen (widescreen): 1680 x 1050 pixels.
24-inch screen (widescreen): 1900 x 1200 pixels.
*/
$bp-desktop: 1900px;
$bp-laptop: 1440px;
$bp-responsive: 1280px;
$bp-tablet: 1024px;
$bp-mobile: 768px;
$bp-mobile-sm: 680px;

$header-height: 130px;
$container-width: 85%; // 1366px;

$sidespace: 25px;
$sidespace-md: 30px;
$sidespace-lg: 45px;
$sidespace-xl: 50px;
$sidespace-xxl: 65px;
$sidespace-xxxl: 75px;

$border-radius: 10px;
$border-radius-sm: 5px;

//forms
$txt-form-color: #4e4e4e;
$color-error: red;
$form-control-height: 40px;

// styles
$txt-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);

$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
$box-shadow-tab: 0 1px 10px 0px rgba(0, 0, 0, 0.1);
$box-shadow-box: 0 0 4px 0 rgba(0, 0, 0, 0.1);

$animation: all 0.5s ease-in-out;

$colors-shadow: #00347026;

@mixin shadow-small {
  box-shadow: 0px 1px 4px 0px $colors-shadow;
}
@mixin shadow-large {
  box-shadow: 0px 5px 15px 0px $colors-shadow;
}

// cross-brawser styles

%flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
} //  @extend %flex;
%flex-inline {
  display: inline-flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-inline-flex;
}
%col {
  -ms-flex-preferred-size: 0;
  -ms-flex-positive: 1;
  max-width: 100%;
  flex-wrap: wrap;
}

// %animation {
//     -webkit-transition: all 0.5s ease-in-out;
//     -moz-transition: all 0.5s ease-in-out;
//     -ms-transition: all 0.5s ease-in-out;
//     -o-transition: all 0.5s ease-in-out;
//     transition: all 0.5s ease-in-out;
// }

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} //  @extend %ellipsis;

%background-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
} //  @extend %background-cover;

/* image fit to box */

%img-fitin-box {
  position: absolute;
  z-index: 0;
  display: block;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  overflow: hidden;
} // @extend %img-fitin-box;

%img-fitin {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  // max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  // max-width: none;
  z-index: -1000;
  overflow: hidden;
}
/*end image fit to box */

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  -o-transition: $property;
  transition: $property;
} // .clasname { @include transition(all 0.5s ease-in-out); }

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
} // @include transform();

@mixin box-shadow($property) {
  -webkit-box-shadow: $property;
  -moz-box-shadow: $property;
  -ms-box-shadow: $property;
  box-shadow: $property;
} // @include box-shadow($box-shadow);

@mixin text-shadow($property) {
  -webkit-text-shadow: $property;
  -moz-text-shadow: $property;
  -ms-text-shadow: $property;
  text-shadow: $property;
} // @include text-shadow($txt-shadow);
