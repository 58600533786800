@import "./base.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* BASE */
body {
  color: $txt-color;
  font-size: $font-size;
  font-family: $font-family-regular;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
}
h1 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-weight: normal;
}
h2 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-weight: normal;
}
a {
  color: $txt-color;
  @include transition($animation);
}
a {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}

/*special hover*/
a.hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  @include transition($animation);
  &:before {
    content: "";
    position: absolute;
    overflow: hidden;
    margin: auto;
    height: 1px;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ffffff;
    width: 0px;
    opacity: 0;
    @include transition($animation);
  }
  &:hover {
    text-decoration: none;
    @include transition($animation);
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}
/*end special hover*/
p,
li {
  line-height: 1.7em;
}
p:last-child {
  padding-bottom: 0;
}
li {
  font-family: $font-family-medium;
}

b,
.bold,
.font-bold {
  font-family: $font-family-bold;
}

.animation {
  @include transition($animation);
}
.txt-shadow {
  @include text-shadow($txt-shadow);
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: inline-block;
}

.flex {
  display: flex;
  .flex-col {
    flex-direction: column;
  }
}

/*LAYOUT*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 900px) {
  body {
    overflow-y: scroll;
  }
}
