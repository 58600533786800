@import "../../base.scss";

.article-text {
  display: inline-block;
  width: 95%;
  margin: 10px 0px;
  font-size: 18px;
  font-family: $font-family-medium;
  line-height: 28px;

  &.no-padding {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 40px;
    margin-top: 0;

    p {
      font-size: 16px;
      font-family: $font-family-medium;
    }
  }
  &.medium {
    p {
      font-size: 18px;
      font-family: $font-family-medium;
    }
  }
  &.no-margin {
    margin-bottom: 0;
  }
  &.last {
    p {
      padding-bottom: 15px;
    }
    padding-bottom: 15px;
  }
  &.bulletpoint {
    margin-top: 0;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 15px;
    }
  }

  a {
    color: #1f33cf;
    font-family: $font-family-bold;
  }
  p {
    font-size: 18px;
    font-family: $font-family-medium;
    line-height: 28px;
  }

  b {
    font-family: $font-family-bold;
  }
}

// .video-container, .video-image.video {
//   width: 95%;
//   border-radius: 15px;
//   box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
//   margin-right: 0;
//   margin-left: 0;
//   margin: auto;
//   height: 40vh;
// }

.article-container {
  position: absolute;
  // height: 90vh;
  height: 96vh;
  top: 5vw;
  display: flex;

  h2 {
    font-family: $font-family-black;
    font-family: $font-family-extraBold;
    line-height: 50px;
  }
}
.buttons {
  width: 100%;
  height: 10%;
  margin-bottom: -2%;
  margin-top: 3%;
  display: flex;
  .website {
    background-image: url("/images/website.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 40px;
    cursor: pointer;
  }
  .app-store {
    background-image: url("/images/app-store.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 40px;
    margin-left: -7%;
    cursor: pointer;
  }
  .google-play {
    background-image: url("/images/google-play.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 40px;
    margin-left: -7%;
    cursor: pointer;
  }
}

.left-article-container {
  height: 100%;
  padding: 2vw;
  // width: 47%;
  width: 50%;
  display: flex;
  flex-flow: column;

  .arrow-left {
    // margin-left: 1%;
    background-image: url("/images/arrow-icon.svg");
    background-size: contain;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .logo {
    background-size: contain;
    background-repeat: no-repeat;
    width: 20%;
    height: 13%;
    margin: 20px 0px;
    margin-bottom: 0;
  }
  .neshto {
    overflow-y: auto;
    height: 88%;
    padding-bottom: 5%;
    .video-image {
      background-image: url("/images/opened-article-video.png");
      background-size: cover;
      background-repeat: no-repeat;
      // width: 100%;
      // height: 55%;
      // margin-left: -3%;
      cursor: pointer;

      width: 95%;
      border-radius: 15px;
      // box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
      margin-right: 0;
      margin-left: 0;
      // margin: auto;
      height: 37vh;
    }
  }
  .icons-container {
    width: 20%;
    display: flex;
    margin-right: -4%;
    // margin-top: 2%;

    &.images-logos {
      width: 45%;
      gap: 15px;
    }

    .apple-icon {
      background-image: url("../../assets/appstore-icon.svg");
      background-size: 55%;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      border: 1px solid #bfbfbf;
      border-radius: 7px;
      margin-right: 10%;
      cursor: pointer;
      &.scoutify {
        background-image: url("../../assets/scoutify-app-store.svg");
        background-size: 100%;
        border:none;
        width: 200px;
        height: 110px;
      }
    }
    .play-icon {
      background-image: url("../../assets/google play-icon.svg");
      background-size: 55%;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      border: 1px solid #bfbfbf;
      border-radius: 7px;
      margin-right: 50px;
      cursor: pointer;
      &.scoutify {
        background-image: url("../../assets/scoutify-google-play.svg");
        background-size: 100%;
        border:none;
        width: 200px;
        height: 110px;
      }
    }
    .site-icon {
      background-image: url("../../assets/website-icon.svg");
      background-size: 55%;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      border: 1px solid #bfbfbf;
      border-radius: 7px;
      cursor: pointer;
    }
    .logo-icon-forbes {
      background-image: url("../../assets/Forbes.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 100px;
      height: 100px;
      border-radius: 7px;
      cursor: pointer;
    }
    .logo-icon-kapital {
      background-image: url("../../assets/Kapital.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 100px;
      height: 100px;
      border-radius: 7px;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #e5214e;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #e5214e;
  }
}
.right-article-container {
  background-repeat: no-repeat;
  // background-size: cover;
  background-size: cover;
  background-position: center;
  width: 50%;
  height: 93%;
}

.mobile-article-container {
  width: 100vw;
  h3 {
    padding: 5px;
  }
  .logo {
    background-image: url("/images/Fight-Scout-logo-header.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 150px;
    height: 50px;
    margin-bottom: 4%;
    margin-left: 40%;
  }
  .mobile-neshto {
    .video-image {
      background-image: url("/images/opened-article-video.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 350px;
      cursor: pointer;
    }
    .icons-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-right: -4%;
      // margin-top: 2%;

      &.images-logos {
        gap: 15px;
        justify-content: flex-start;
        padding: 0 7%;

        .logo-icon-forbes,
        .logo-icon-kapital {
          border: none;
        }
      }

      .apple-icon {
        background-image: url("../../assets/appstore-icon.svg");
        background-size: 55%;
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
        border: 1px solid #bfbfbf;
        border-radius: 7px;
        margin-right: 10%;
        cursor: pointer;
        &.scoutify {
          background-image: url("../../assets/scoutify-app-store.svg");
          background-size: 100%;
          border:none;
          width: 150px;
          height: 110px;
        }
      }
      .play-icon {
        background-image: url("../../assets/google play-icon.svg");
        background-size: 55%;
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
        border: 1px solid #bfbfbf;
        border-radius: 7px;
        margin-right: 2%;
        cursor: pointer;
        &.scoutify {
          background-image: url("../../assets/scoutify-google-play.svg");
          background-size: 100%;
          border:none;
          width: 150px;
          height: 110px;
        }
      }
      .site-icon {
        background-image: url("../../assets/website-icon.svg");
        background-size: 55%;
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
        border: 1px solid #bfbfbf;
        border-radius: 7px;
        cursor: pointer;
      }
      .logo-icon-forbes {
        background-image: url("../../assets/Forbes.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 50%;
        height: 100px;
        cursor: pointer;
        // flex: 1;
      }
      .logo-icon-kapital {
        background-image: url("../../assets/Kapital.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 50%;
        height: 100px;
        border: none;
        cursor: pointer;
        // flex: 1;
      }
    }
  }
}

.mobile-article-text {
  padding: 10px;
  width: 90%;
  margin-left: 5%;
  font-size: 16px;
  font-family: $font-family-medium;

  &.no-padding {
    padding-bottom: 0;
    padding-top: 40px;

    p {
      font-size: 16px;
      font-family: $font-family-medium;
    }
  }
  &.no-margin {
    margin-bottom: 0;
  }
  &.bulletpoint {
    font-size: 16px;
    font-family: $font-family-medium;
    margin: 0 auto;
    padding: 0 10px;

    p {
      font-size: 16px;
      font-family: $font-family-medium;
    }
  }

  p {
    font-size: 16px;
    font-family: $font-family-medium;
  }

  // .no-padding {
  //   padding-top: 50px;
  //   background-color: pink;
  //   font-size: 16px;
  //   font-family: $font-family-medium;
  // }
}

.mobile-right-article-container {
  background-repeat: no-repeat;
  background-size: cover;
  // background-size: contain;
  background-position: center;
  width: 100%;
  height: 50vh !important;
}

.mobile-arrow-left {
  margin-top: 3%;
  margin-left: 6%;
  background-image: url("/images/arrow-icon.svg");
  background-size: contain;
  width: 50px;
  height: 30px;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .article-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 1200px) {
  .article-text {
    font-size: 16px;
  }

  .mobile-article-container {
    .mobile-neshto {
      .icons-container.images-logos {
        padding: 0 6%;

        .logo-icon-forbes,
        .logo-icon-kapital {
          height: 150px;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .article-text {
    font-size: 16px;
  }
  .buttons {
    height: 150px;
    flex-direction: column;
    .upper-buttons {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-bottom: 5%;
      .app-store {
        margin-left: 7%;
      }
    }
    .lower-buttons {
      display: flex;
      width: 100%;
      justify-content: center;
      .website {
        width: 160px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .mobile-arrow-left {
    margin-left: 3%;
  }
  .mobile-article-container {
    .logo {
      width: 100%;
      margin-left: 0%;
    }
  }
  .buttons {
    .lower-buttons {
      .website {
        width: 120px;
      }
    }
    .google-play {
      width: 150px;
    }
  }
}
