@font-face {
  font-family: "normal";
  src: url("fonts/Typedepot - Lexis Medium.ttf");
  src: url("fonts/Typedepot - Lexis Medium.ttf?#iefix") format("embedded-opentype"),
    url("fonts/Typedepot - Lexis Medium.ttf#Typedepot - Lexis Medium") format("svg"),
    url("fonts/Typedepot - Lexis Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "regular";
  src: url("fonts/Montserrat-Regular.ttf");
  src: url("fonts/Montserrat-Regular.ttf?#iefix") format("embedded-opentype"),
    url("fonts/Montserrat-Regular.ttf#Montserrat-Regular") format("svg"),
    url("fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "light";
  src: url("fonts/Typedepot - Lexis Light.ttf");
  src: url("fonts/Typedepot - Lexis Light.ttf?#iefix") format("embedded-opentype"),
    url("fonts/Typedepot - Lexis Light.ttf#Typedepot - Lexis Light") format("svg"),
    url("fonts/Typedepot - Lexis Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bold";
  src: url("fonts/Typedepot - Lexis Bold.ttf");
  src: url("fonts/Typedepot - Lexis Bold.ttf?#iefix") format("embedded-opentype"),
    url("fonts/Typedepot - Lexis Bold.ttf#Typedepot - Lexis Bold") format("svg"),
    url("fonts/Typedepot - Lexis Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "extraBold";
  src: url("fonts/Montserrat-ExtraBold.ttf");
  src: url("fonts/Montserrat-ExtraBold.ttf?#iefix") format("embedded-opentype"),
    url("fonts/Montserrat-ExtraBold.ttf#Montserrat-ExtraBold") format("svg"),
    url("fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "black";
  src: url("fonts/Montserrat-Black.ttf");
  src: url("fonts/Montserrat-Black.ttf?#iefix") format("embedded-opentype"),
    url("fonts/Montserrat-Black.ttf#Montserrat-Black") format("svg"),
    url("fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?qtvcbr");
  src: url("fonts/icomoon.eot?qtvcbr#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?qtvcbr") format("truetype"),
    url("fonts/icomoon.woff?qtvcbr") format("woff"),
    url("fonts/icomoon.svg?qtvcbr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
